export default {
  'notifications.OrderPaid': 'Order paid',
  'notifications.UserLogged': 'User logged in',
  'notifications.list': 'List',
  'notifications.TopicFinished': 'Topic finished',
  'notifications.CourseAssigned': 'Course assigned',
  'notifications.PaymentRegistered': 'Payment registered',
  'notifications.PermissionRoleRemoved': 'Permission role removed',
  'notifications.PermissionRoleChanged': 'Permission role changed',
  'notifications.CartOrderPaid': 'Cart order paid',
  'notifications.CourseAccessFinished': 'Course access finished',
  'notifications.Logout': 'Logout',
  'notifications.UserRemovedFromGroup': 'User removed from group',
  'notifications.UserAddedToGroup': 'User added to group',
  'notifications.CoursedPublished': 'Course published',
  'notifications.ResetPassword': 'Reset password',
  'notifications.CourseAccessStarted': 'Course access started',
  'notifications.CartOrderSuccess': 'Order success',
  'notifications.AccountRegistered': 'Account registered',
  'notifications.ForgotPassword': 'Forgot password',
  'notifications.Login': 'Login',
  'notifications.TopicTypeChanged': 'Topic type changed',
  'notifications.AccountDeleted': 'Account deleted',
  'notifications.AccountBlocked': 'Account blocked',
  'notifications.CourseFinished': 'Course finished',
  'notifications.PaymentSuccess': 'Payment success',
  'notifications.PdfCreatedEvent': 'PDF created',
  'notifications.CourseTutorUnassigned': 'Course tutor unassigned',
  'notifications.CourseUnassigned': 'Course uassigned',
  'notifications.AccountMustBeEnableByAdmin': 'Account must be enable by Admin',
  'notifications.SettingPackageConfigUpdated': 'Setting package config updated',
  'notifications.CourseTutorAssigned': 'Course tutor assigned',
  'notifications.AccountConfirmed': 'Account confirmed',
};
